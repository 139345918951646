import React from "react";
import cx from "classnames";
import {
    fileViewEntriesAsIdsAndNames,
    FileViewEntry,
} from "../viewModel/FileViewEntry";
import { Button } from "react-bootstrap";
import { startMoving } from "../../../store/features/folder/moveFilesAndFoldersSlice";
import { useAppDispatch } from "../../../store/hooks";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import PermissionConstants from "../../../common/permissions/PermissionConstants";

interface Props {
    selectedFiles: FileViewEntry[];
    selectedFolders: FileViewEntry[];
    organisationId: string;
}

export const MoveSelectedEntriesButton: React.FC<Props> = ({
    selectedFiles,
    selectedFolders,
    organisationId,
}) => {
    const dispatch = useAppDispatch();

    const canMoveFolders = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolder.move,
    ).hasPermission;

    const canMoveFiles = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.move,
    ).hasPermission;

    return (
        (selectedFiles.length || selectedFolders.length || null) &&
        (canMoveFolders || canMoveFiles) && (
            <Button
                variant="outline-secondary"
                className={cx("mt-1")}
                onClick={() => {
                    dispatch(
                        startMoving({
                            files: fileViewEntriesAsIdsAndNames(selectedFiles),
                            folders:
                                fileViewEntriesAsIdsAndNames(selectedFolders),
                        }),
                    );
                }}
            >
                Move selected
            </Button>
        )
    );
};
